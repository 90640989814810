'use client';

import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useContext } from 'react';
import { OptionTypeBase } from 'react-select';

import Button from '@/components/Button';
import { If } from '@/components/UtilitiesComponents';
import IconWhatsapp from '@/components/v3/Icon/icons/Whatsapp';
import LINKS from '@/constants/links';
import { AmplitudeContext } from '@/contexts/amplitude';
import { useAdquiraExperiment } from '@/hooks/useAdquiraExperiment';
import { useMgm } from '@/hooks/useMgm';
import { tpvOptions } from '@/resources/inbound';
import useStore from '@/store';
import handleHash from '@/utils/handleHash';
import { cnpjMask, dontAllowWhitespaceMask, phoneMask } from '@/utils/masks';

import { TInboundFormData } from '../../types';
import { schema } from './validation';

const FormikSelectInput = dynamic(
  () => import('./components/InboundV2FormikSelectInput/index'),
  { ssr: false },
);

const FormikTextInput = dynamic(
  () => import('./components/InboundV2FormikTextInput/index'),
);

type InboundV2FormProps = {
  onSubmit: (
    data: TInboundFormData,
    actions: FormikHelpers<TInboundFormData>,
  ) => void;
  shouldShowTitle?: boolean;
  analyticsEventType?: string;
  analyticsSectionReference?: string;
};

const InboundV2Forms: React.FC<InboundV2FormProps> = ({
  onSubmit,
  shouldShowTitle = true,
  analyticsEventType = 'generate_lead',
  analyticsSectionReference,
}) => {
  const { analytics } = useContext(AmplitudeContext);
  const { shouldEnterAdquiraExperiment } = useAdquiraExperiment();
  const store = useStore();
  const [isMgm] = useMgm();

  const formInitialValues = {
    name: store.user?.full_name,
    email: store.user?.email,
    phone: store.user?.phone,
    document: '',
    tpv: '',
  };

  const trackOnBlurFields = async (value: string, name = 'faturamento') => {
    analytics?.track({
      event_type: `step ${name} completed`,
      event_properties: {
        [`input_${name}`]: await handleHash(value),
        name: `step ${name} completed`,
        description: `Evento disparado quando o usuário preenche o ${name}. Como propriedade será retornada qual o ${name} digitado`,
      },
    });
  };

  const trackInboundCta = (cta: string | null) => {
    const selectTpv = () => {
      return document.querySelector('#tpv')?.textContent;
    };

    analytics?.track({
      event_type: analyticsEventType,
      event_properties: {
        name: analyticsEventType,
        value: selectTpv(),
        description: `Evento disparado quando o usuario envia o formulario do adquira.`,
        cta_reference: cta,
        section_reference: analyticsSectionReference,
      },
    });
  };

  const handleOnBlur = (
    event: React.FocusEvent<HTMLInputElement, Element>,
    callback: (value: string, name: string) => void,
  ) => {
    const { value, name } = event.target;
    if (value) {
      callback(value, name);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...formInitialValues,
        channel: 'whatsapp',
      }}
      validationSchema={schema(isMgm)}
      onSubmit={(data, actions) => {
        trackInboundCta('Continuar pelo Whatsapp');
        onSubmit(data, actions);
      }}
    >
      {props => {
        return (
          <>
            <If condition={shouldShowTitle}>
              <div className="hidden md:flex flex-col gap-16 justify-center p-24 md:p-0 mt-[65px] md:mt-0 w-full">
                <h1
                  className={
                    shouldEnterAdquiraExperiment
                      ? 'font-display text-lg xl:text-2xl font-bold leading-5 md:leading-6'
                      : 'font-display text-xl md:text-2xl font-bold leading-5 md:leading-6'
                  }
                >
                  {shouldEnterAdquiraExperiment
                    ? 'Informe abaixo os seus dados'
                    : 'Preencha seus dados'}
                </h1>
              </div>
            </If>

            <Form
              onSubmit={props.handleSubmit}
              className="grid gap-16 w-full"
              name="Inbound"
            >
              <FormikTextInput
                name="name"
                label="Nome Completo"
                maskFunction={dontAllowWhitespaceMask}
                onBlurCallBack={event => handleOnBlur(event, trackOnBlurFields)}
              />

              <FormikTextInput
                type="tel"
                name="phone"
                label="Celular com DDD"
                maskFunction={phoneMask}
                onBlurCallBack={event => handleOnBlur(event, trackOnBlurFields)}
              />
              <FormikTextInput
                type="email"
                name="email"
                label="E-mail"
                onBlurCallBack={event => handleOnBlur(event, trackOnBlurFields)}
              />

              <If condition={isMgm}>
                <div className="flex flex-col gap-8">
                  <FormikTextInput
                    type="text"
                    name="document"
                    label="CNPJ"
                    aria-label="CNPJ"
                    maskFunction={cnpjMask}
                    onBlurCallBack={event =>
                      handleOnBlur(event, trackOnBlurFields)
                    }
                  />
                </div>
              </If>

              <Field id="tpv" name="tpv" aria-label="Faturamento mensal">
                {(fieldProps: FieldProps) => (
                  <FormikSelectInput
                    label="Faturamento mensal em cartão"
                    description="Estimativa de vendas feitas em cartão de crédito e débito."
                    options={tpvOptions}
                    id="react-select-tpvSelect-input"
                    aria-label="Faturamento mensal"
                    name={fieldProps.field.name}
                    onBlurCallBack={(option: OptionTypeBase) => {
                      trackOnBlurFields(option.value);
                    }}
                    {...fieldProps}
                  />
                )}
              </Field>

              <p
                className={
                  shouldEnterAdquiraExperiment
                    ? 'text-center xl:text-left text-display-700 paragraph-14 my-0 xl:my-8'
                    : 'text-center text-display-700 paragraph-14'
                }
              >
                Ao continuar, você concorda com nosso{' '}
                <Link
                  href={LINKS.PRIVACY_POLICY}
                  className="text-stone-500 underline cursor-pointer paragraph-14"
                  rel="noreferrer"
                  target="_blank"
                  aria-label="Aviso de Privacidade"
                >
                  {shouldEnterAdquiraExperiment
                    ? 'Aviso de Privacidade.'
                    : 'Aviso de Privacidade'}
                </Link>
              </p>

              <Button
                id="tpv-submit"
                onClick={() => {
                  props.setFieldValue('channel', 'whatsapp');
                  props.handleSubmit();
                }}
                className="gap-8 w-full btn btn-large btn-primary"
              >
                <IconWhatsapp fill="white" className="m-10" />
                Continuar pelo WhatsApp
              </Button>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default InboundV2Forms;
