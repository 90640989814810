import { useEffect, useState } from 'react';

const tokenLifetime = 20 * 60 * 1000; // 20 minutes

const MRT_BFF_WEB_URL = process.env.MRT_BFF_WEB_URL || '';
export const useMrtCSRFToken = () => {
  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    const url = `${MRT_BFF_WEB_URL}/csrf`;
    const fetchCSRF = async () => {
      try {
        const response = await fetch(url, {
          method: 'GET',
          credentials: 'include',
        });
        const data = await response.json();
        setCsrfToken(data.csrfToken);
      } catch (error) {
        console.error('Error refreshing MRT_BFF csrf token:', error);
      }
    };
    fetchCSRF();
    const refreshInterval = setInterval(fetchCSRF, tokenLifetime - 5000); // Refresh Token 5 seconds before expiration

    return () => clearInterval(refreshInterval);
  }, []);

  return csrfToken;
};
