import { usePathname, useSearchParams } from 'next/navigation';

import useAmplitudeExperiment from './useAmplitudeExperiment';

interface HookResponse {
  shouldEnterAdquiraExperiment: boolean;
}

export function useAdquiraExperiment(): HookResponse {
  const pathname = usePathname() || '/';
  const searchParams = useSearchParams();
  const searchExperiment = searchParams?.get('experiment');

  const assignExperimentOnlyOnAdquiraPage = pathname.includes('/adquira');

  const { activeVariant } = useAmplitudeExperiment(
    process.env.AMPLITUDE_EXPERIMENT_ADQUIRA,
    assignExperimentOnlyOnAdquiraPage,
  );

  return {
    shouldEnterAdquiraExperiment:
      activeVariant === 'test' ||
      searchExperiment === process.env.AMPLITUDE_EXPERIMENT_ADQUIRA,
  };
}
