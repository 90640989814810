import buildUrlUtm from '@/utils/buildUrlUtm';
import { TInboundFormData } from '../types';

const MRT_BFF_WEB_URL = process.env.MRT_BFF_WEB_URL || '';

export const sendOneApiWhatsAppData = async (
  params: TInboundFormData,
  csrfToken: string,
): Promise<void> => {
  const url = `${MRT_BFF_WEB_URL}/inbound/v2/form-adquira`;
  const requestBody = {
    ...params,
    urlWithParams: buildUrlUtm(`${window.location.origin}/`),
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': csrfToken,
      },
      body: JSON.stringify(requestBody),
      credentials: 'include',
    });

    if (!response.ok) {
      const errorMessage = `Request to ${url} failed with status ${response.status}: ${response.statusText}`;
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error('Failed to send MRT_BFF data:', error);
    throw error;
  }
};
